import classNames from "classnames";
import React, { useMemo } from "react";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { Typography } from "components/ui/Typography";
import { requirePropertyOf } from "utils/security";
import { useStyles } from "../styles";
import { useTypographyVariant } from "../useTypographyVariant";
import type { TBaseEntityNodeProps } from "../types";

export const EntityNodeContent: FC<
	TBaseEntityNodeProps & {
		text: React.ReactNode;
		icon?: React.ReactNode;
		withIcon?: boolean;
		inactive?: boolean;
		tooltipOnOverflow?: boolean;
		iconClassName?: string;
	}
> = ({
	text,
	withIcon,
	inactive,
	size,
	icon,
	className,
	innerRef,
	id,
	variant = "standard",
	noWrap = true,
	relative,
	textClassName,
	iconClassName,
	tooltipOnOverflow,
	delayShowTooltip
}) => {
	const classes = useStyles();
	const sizeClassName = useMemo(() => requirePropertyOf(classes, size), [classes, size]);

	const typographyVariant = useTypographyVariant(size, variant);

	const iconComponent = useMemo(
		() => (withIcon ? <div className={classNames(classes.icon, sizeClassName, iconClassName)}>{icon}</div> : null),
		[withIcon, classes.icon, sizeClassName, iconClassName, icon]
	);

	return (
		<div
			id={id}
			ref={innerRef}
			className={classNames(
				classes.labelTextContent,
				sizeClassName,
				{ [classes.inactive]: inactive, [classes.wrap]: !noWrap },
				className
			)}>
			{iconComponent}
			{tooltipOnOverflow ? (
				<TooltipOnOverflow
					delayShow={delayShowTooltip}
					className={textClassName}
					textVariant={typographyVariant}
					content={text}
					multiline={!noWrap}
					relative={relative}
				/>
			) : (
				<Typography className={textClassName} variant={typographyVariant} noWrap={noWrap} relative={relative}>
					{text}
				</Typography>
			)}
		</div>
	);
};
