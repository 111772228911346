import { compiledClientConfig } from "config";
import { apiReq } from "./api/apiReq";

const DEFAULT_VERSION_NAME = "unknown";

interface IDeploymentInfo {
	GITHUB_REF: string;
	RUN_NUMBER: string;
	COMMIT_HASH: string;
}

const isDeploymentInfo = (val: unknown): val is IDeploymentInfo => {
	const asDeploymentInfo = val as IDeploymentInfo;
	return (
		typeof asDeploymentInfo === "object" &&
		typeof asDeploymentInfo.GITHUB_REF === "string" &&
		typeof asDeploymentInfo.RUN_NUMBER === "string" &&
		typeof asDeploymentInfo.COMMIT_HASH === "string"
	);
};

const getDeploymentInfo = async (): Promise<IDeploymentInfo | null> => {
	try {
		const { data } = await apiReq("GET", "/deploymentInfo", null, {
			pathPrefix: compiledClientConfig.basePrefix
		});
		return isDeploymentInfo(data) ? data : null;
	} catch (_error) {
		return null;
	}
};

export const getCurrentVersion = async (): Promise<string> => {
	const deploymentInfo = await getDeploymentInfo();
	if (deploymentInfo && isDeploymentInfo(deploymentInfo)) {
		return `frontend-#${deploymentInfo.RUN_NUMBER}-${deploymentInfo.COMMIT_HASH}`;
	}
	return DEFAULT_VERSION_NAME;
};
