import classNames from "classnames";
import React from "react";
import { useClicks } from "hooks/useClicks";
import { useStyles } from "./styles";

export type TCardProps = {
	content: React.ReactNode;
	disabled?: boolean;
	expanded?: boolean;
	fixedWidth?: boolean;
	header: React.ReactNode;
	onClick?: () => void;
	onDoubleClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
	selected?: boolean;
	size: "large" | "small";
	topActions?: React.ReactNode;
};

export const Card: FC<TCardProps> = props => {
	const classes = useStyles();
	const {
		className,
		content,
		disabled,
		expanded,
		fixedWidth,
		header,
		innerRef,
		onClick: propOnClick,
		onDoubleClick: propOnDoubleClick,
		selected,
		size,
		topActions
	} = props;

	const { onClick, onDoubleClick } = useClicks(propOnClick, propOnDoubleClick);

	return (
		<div
			className={classNames(
				classes.container,
				size === "large" ? classes.large : classes.small,
				{
					[classes.clickable]: !!onClick || !!onDoubleClick,
					[classes.disabled]: disabled,
					[classes.expanded]: expanded,
					[classes.fixedWidth]: fixedWidth,
					[classes.selected]: selected
				},
				className
			)}
			ref={innerRef}
			onClick={onClick}
			onDoubleClick={onDoubleClick}>
			<div className={classes.header}>
				{topActions ? <div className={classes.topActions}>{topActions}</div> : null}
				<div className={classes.headerContent}>{header}</div>
			</div>
			<div className={classes.content}>{content}</div>
		</div>
	);
};
