import type { TBaseEntityNodeProps } from "./types";

export const useTypographyVariant = (size: TBaseEntityNodeProps["size"], variant: TBaseEntityNodeProps["variant"]) => {
	switch (size) {
		case "large":
			switch (variant) {
				case "bold":
				case "link":
					return "title_sb";
				default:
					return "title_med";
			}
		case "medium":
			switch (variant) {
				case "bold":
				case "link":
					return "body_sb";
				default:
					return "body_reg";
			}
		case "small":
			switch (variant) {
				case "bold":
				case "link":
					return "text_title_sb";
				default:
					return "text_reg";
			}
		case "tiny":
			switch (variant) {
				case "bold":
				case "link":
					return "text_sm_sb";
				default:
					return "text_sm_reg";
			}
		default:
			return undefined;
	}
};
