import { List, Record } from "immutable";
import { compiledClientConfig } from "config";
import { ApplicationModel } from "models/ApplicationModel";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { getMaintainerModels } from "utils/maintainers";
import type { TTicketDuration } from "utils/durationsOptions";

const defaults = {
	adapterless: false,
	agentTokenId: null as string | null,
	allowedDurations: null as List<TTicketDuration> | null,
	allowsRequests: true,
	application: null as ApplicationModel | null,
	applicationId: null as string | null,
	autoAssignRecommendedResourceMaintainers: false,
	autoAssignRecommendedResourceOwner: false,
	canCreateActors: false as boolean,
	canEditPermissions: true as boolean,
	configuration: null as { [key: string]: string } | null,
	defaultAllowsRequests: false as boolean,
	defaultApprovalAlgorithm: new ApprovalAlgorithmModel(),
	id: "",
	imageUrl: null as string | null,
	isDeleted: false,
	lastFullSync: null as Date | null,
	lastResourcesSync: null as Date | null,
	maintainerGroupId: null as string | null,
	maintainers: null as List<TMaintainerModel> | null,
	manual: false,
	name: "",
	nextSyncTime: new Date(),
	notifyAboutExternalPermissions: false,
	oauthConfiguration: false as boolean,
	ownerId: "",
	prerequisitePermissionId: null as string | null,
	readonly: false,
	resourcesCount: 0,
	syncLimitInMinutes: 0,
	virtual: false
};

export type TIntegrationModel = typeof defaults;

export class IntegrationModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const {
			adapterless,
			agentTokenId,
			allowedDurations,
			allowsRequests,
			application,
			applicationId,
			autoAssignRecommendedResourceMaintainers,
			autoAssignRecommendedResourceOwner,
			canCreateActors,
			canEditPermissions,
			configuration,
			defaultAllowsRequests,
			defaultApprovalAlgorithm,
			id,
			imageUrl,
			isDeleted,
			lastFullSync,
			lastResourcesSync,
			maintainerGroupId,
			maintainers,
			manual,
			name,
			nextSyncTime,
			notifyAboutExternalPermissions,
			oauthConfiguration,
			ownerId,
			prerequisitePermissionId,
			readonly,
			resourcesCount,
			syncLimitInMinutes,
			virtual
		} = data as typeof defaults;
		const modelApplication = application ? ApplicationModel.fromServerData(application) : null;
		return new IntegrationModel({
			adapterless,
			agentTokenId: agentTokenId || null,
			allowedDurations: allowedDurations ? List(allowedDurations) : null,
			allowsRequests,
			application: modelApplication,
			applicationId,
			autoAssignRecommendedResourceMaintainers,
			autoAssignRecommendedResourceOwner,
			canCreateActors,
			canEditPermissions,
			configuration,
			defaultAllowsRequests,
			defaultApprovalAlgorithm: defaultApprovalAlgorithm
				? ApprovalAlgorithmModel.fromServerData(defaultApprovalAlgorithm)
				: undefined,
			id,
			imageUrl:
				compiledClientConfig.spog && imageUrl ? `/${compiledClientConfig.basePrefix}` + imageUrl : imageUrl || null,
			isDeleted,
			lastFullSync: lastFullSync ? new Date(lastFullSync) : null,
			lastResourcesSync: lastResourcesSync ? new Date(lastResourcesSync) : null,
			maintainerGroupId,
			maintainers: getMaintainerModels(maintainers),
			manual,
			name,
			notifyAboutExternalPermissions,
			nextSyncTime: new Date(nextSyncTime),
			oauthConfiguration,
			ownerId,
			prerequisitePermissionId,
			readonly,
			resourcesCount: Number(resourcesCount),
			syncLimitInMinutes,
			virtual
		});
	}

	get actorless() {
		return this.canCreateActors && !this.canEditPermissions;
	}
}
