import { AbortError } from "utils/errors/abortError";

export async function handleAbortError<T>(
	promise: Promise<T>,
	handleResult: (result: T) => void,
	handleAbort?: () => unknown
) {
	try {
		const result = await promise;
		handleResult(result);
	} catch (error) {
		if (!(error instanceof Error) || error.message !== "Request aborted" || !(error instanceof AbortError)) {
			throw error;
		} else {
			handleAbort?.();
		}
	}
}

export async function ignoreAbortError<T>(promise: Promise<T>, handleResult: (result: T) => void) {
	return handleAbortError(promise, handleResult);
}
