import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	loadingUserInfoPage: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		height: "100%",
		top: "0",
		left: "0",
		position: "absolute",
		backgroundColor: "var(--surface-page-contrast-background-color)",
		userSelect: "none",
		overflow: "auto",
		contain: "strict"
	},

	overlays: {
		zIndex: 102,
		width: "100%",
		height: "100%",
		top: "0",
		left: "0",
		position: "absolute",
		pointerEvents: "none",

		"& > *": {
			pointerEvents: "initial"
		}
	}
});
