import { createUseStyles } from "react-jss";
import { BP, BP_MEDIA_QUERIES } from "utils/ui/breakpoints";

const FOOTER_HEIGHT_PX = 68;

export const useStyles = createUseStyles({
	alignedCenter: {
		alignItems: "center",
		display: "inline-flex"
	},
	body: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x10, 40px)",
		flexGrow: 1
	},
	container: {
		backgroundColor: "var(--color-white)",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x10, 40px)",
		height: "100%",
		overflow: "auto",
		padding: "var(--spacing-x8, 32px)",
		position: "relative",
		flex: 1,
		maxWidth: "100%",
		"& > *": {
			minWidth: "100%"
		},
		"&$withFooter": {
			height: `calc(100% - ${FOOTER_HEIGHT_PX}px)`,
			paddingBottom: 0
		}
	},
	header: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x6, 24px)"
	},
	headerBottom: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x5, 20px)",
		[BP_MEDIA_QUERIES.TWENTY]: {
			alignItems: "flex-end",
			flexDirection: "row",
			justifyContent: "space-between"
		}
	},
	headerBottomActions: {
		alignItems: "flex-end",
		display: "flex",
		gap: "var(--spacing-x3, 12px)",
		justifyContent: "flex-end",
		flexWrap: "wrap",
		[BP_MEDIA_QUERIES.TWENTY]: {
			alignSelf: "initial",
			alignItems: "flex-end",
			flexDirection: "row",
			justifyContent: "space-between",
			flexWrap: "nowrap"
		}
	},
	headerContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x5, 20px)"
	},
	headerTop: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x5, 20px)",
		justifyContent: "space-between"
	},
	titleSubtitle: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x05, 2px)",
		maxWidth: "700px",
		minWidth: "146px"
	},
	footer: {
		alignItems: "center",
		backgroundColor: "var(--color-white)",
		bottom: 0,
		boxShadow: "0px -4px 5px 1px rgba(0, 0, 0, 0.20)",
		display: "grid",
		gap: "var(--spacing-x3, 12px)",
		height: `${FOOTER_HEIGHT_PX}px`,
		left: 0,
		padding: "var(--spacing-x3, 12px) var(--spacing-x8, 32px)",
		position: "absolute",
		right: 0,
		width: "100%",
		"& > $footerLeft, $footerCenter, $footerRight": {
			display: "flex",
			gap: "var(--spacing-x2, 8px)"
		}
	},
	footerLeft: {
		justifySelf: "flex-start",
		justifyContent: "flex-start"
	},
	footerCenter: {
		justifySelf: "center",
		justifyContent: "center"
	},
	footerRight: {
		justifySelf: "flex-end",
		justifyContent: "flex-end"
	},
	wrapper: {
		display: "flex",
		flexDirection: "row",
		height: "100%",
		"&$withCenteredContainer": {
			width: "100%",
			// Padding and not max width in order to support scrollbar on all page layout width
			paddingLeft: `max(0px, calc((100% - ${BP.NINETY}px) / 2))`,
			paddingRight: `max(0px, calc((100% - ${BP.NINETY}px) / 2))`,
			"&$withWrapperScrollbar": {
				overflow: "auto",
				[BP_MEDIA_QUERIES.NINETY]: {
					"& > $container": {
						overflow: "unset",
						minHeight: "fit-content"
					}
				}
			}
		}
	},
	withFooter: {},
	withCenteredContainer: {},
	withWrapperScrollbar: {}
});
