import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	overlays: {
		width: "100%",
		height: "100%",
		position: "absolute",
		zIndex: 102,
		pointerEvents: "none",
		left: 0,
		top: 0,
		"&:empty": {
			display: "none"
		},
		"& > *": {
			pointerEvents: "initial"
		}
	}
});
