import classNames from "classnames";
import React, { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import { useSystemOverlays } from "context/overlaysContext";
import { usePropagationContext } from "context/propagationContext";
import { useStopPropagation } from "hooks/useStopPropagation";
import { IUseTooltipOptions, useTooltip } from "hooks/useTooltip";
import { useStyles } from "./styles";
import { Typography } from "../Typography";

export type TTooltipProps = Omit<IUseTooltipOptions, "interactive"> & {
	clean?: boolean; // If true, the tooltip will rendered with no styles
	content: React.ReactNode | FC; // The tooltip content
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-redundant-type-constituents
	children: React.ReactElement<any & { innerRef?: React.Ref<any> }, any>; // The element to which the tooltip is attached
	tooltipRef?: React.MutableRefObject<HTMLElement | null>; // The ref of the tooltip itself
	triggerRef?: React.MutableRefObject<HTMLElement | null>; // The ref of the element to which the tooltip is attached
	notInteractive?: boolean; // If true, the tooltip will not be interactive
};

export const TOOLTIP_AVAILABLE_TO_ATTACH_COMPONENTS = ["div", "span", "button", "a"];

const Tooltip: FC<TTooltipProps> = ({
	children,
	clean = false,
	className,
	content: Content,
	tooltipRef: propTooltipRef,
	triggerRef: propTriggerRef,
	notInteractive = false,
	...tooltipOptions
}) => {
	const classes = useStyles();
	const systemOverlays = useSystemOverlays();

	const { visible, setTooltipRef, tooltipProps, setTriggerRef, tooltipRef, triggerRef } = useTooltip({
		...tooltipOptions,
		interactive: !notInteractive
	});

	const {
		actions: { addPropagationElement, removePropagationElement }
	} = usePropagationContext();

	useEffect(() => {
		addPropagationElement(tooltipRef);
		return () => removePropagationElement(tooltipRef);
	}, [tooltipRef, addPropagationElement, removePropagationElement]);

	const stopPropagation = useStopPropagation();

	const contentDiv = useMemo(
		() =>
			Content ? (
				typeof Content === "string" ? (
					<Typography variant="body_reg">{Content}</Typography>
				) : typeof Content === "function" ? (
					<Content />
				) : (
					Content
				)
			) : null,
		[Content]
	);

	const childrenRef = useMemo(
		() =>
			typeof children.type === "string" && TOOLTIP_AVAILABLE_TO_ATTACH_COMPONENTS.includes(children.type)
				? { ref: setTriggerRef }
				: { innerRef: setTriggerRef },
		[children.type, setTriggerRef]
	);

	const childrenToRender = useMemo(
		() => React.cloneElement(children, { ...children.props, ...childrenRef }),
		[children, childrenRef]
	);

	useEffect(() => {
		if (propTriggerRef !== undefined) {
			propTriggerRef.current = triggerRef;
		}
	}, [propTriggerRef, triggerRef]);

	useEffect(() => {
		if (propTooltipRef !== undefined) {
			propTooltipRef.current = tooltipRef;
		}
	}, [propTooltipRef, tooltipRef]);

	if (!Content) return children;

	return (
		<React.Fragment>
			{childrenToRender}
			{visible &&
				createPortal(
					<div
						ref={setTooltipRef}
						{...tooltipProps}
						className={classNames(classes.baseTooltip, { [classes.tooltip]: !clean }, className)}
						onMouseDown={stopPropagation}
						onClick={stopPropagation}>
						{contentDiv}
					</div>,
					systemOverlays ?? document.body
				)}
		</React.Fragment>
	);
};

const MemoizedTooltip = React.memo(Tooltip) as typeof Tooltip;
export { MemoizedTooltip as Tooltip };
