import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserEntity } from "components/common/entities/UserEntity";
import { UserCard } from "components/common/UserCard";
import { TitleTooltip } from "components/ui/TitleTooltip";
import { Typography } from "components/ui/Typography";
import { useIntegrations } from "hooks/useIntegrations";
import { devLog } from "utils/devtools/devLogging";
import { TextLabel } from "../TextLabel";
import { useSelectLabel } from "../useSelectLabel";
import type { IntegrationModel } from "models/IntegrationModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { UserModel } from "models/UserModel";
import type { TInherit, TLabelComponent } from "utils/ui/select";

const UserLabel: TLabelComponent<UserModel> = ({ disabled = false, value, withIcon, innerRef }) => {
	const { size, ...otherProps } = useSelectLabel(disabled, withIcon);

	return <UserEntity innerRef={innerRef} user={value} noWrap relative size={size as "medium"} {...otherProps} />; // size has to medium since the type requires it but it is actually medium since we don't provide size to useSelectLabel
};
UserLabel.isSelectLabel = true;

const UserWithoutEmailLabel: TLabelComponent<UserModel> = ({ disabled = false, innerRef, value, withIcon }) => {
	const entityProps = useSelectLabel(disabled, withIcon);

	return <UserEntity innerRef={innerRef} user={value} withoutEmail noWrap relative {...entityProps} />;
};
UserWithoutEmailLabel.isSelectLabel = true;

const InheritUserLabel: FC<{ user?: string | UserModel; inheritText: string; disabled?: boolean }> = ({
	disabled,
	user,
	inheritText
}) => {
	const { t } = useTranslation();
	if (!user) {
		devLog({
			message: "InheritUserLabel: user was not provided (this should not happen)",
			level: "error"
		});
	}

	return (
		<TitleTooltip
			header={<Typography variant="body_sb">{inheritText}</Typography>}
			body={
				user ? (
					<UserCard user={user} />
				) : (
					<Typography variant="text_sm_reg">{t("shared.unableToRetrieveData")}</Typography>
				)
			}>
			<TextLabel variant="bold" disabled={disabled} value={inheritText} />
		</TitleTooltip>
	);
};
const ResourceInheritUserLabel: TLabelComponent<
	TInherit,
	{ integrationResource?: IntegrationResourceModel; inheritUser?: UserModel }
> = ({ disabled = false, integrationResource, inheritUser }) => {
	const { t } = useTranslation();
	const integrations = useIntegrations();
	const owner = useMemo(() => {
		if (inheritUser) return inheritUser;
		if (!integrationResource) return;
		if (integrationResource.ownerId) return integrationResource.ownerId;

		const integration = integrationResource.integration?.ownerId
			? integrationResource.integration
			: integrations?.get(integrationResource.integrationId);
		return integration?.ownerId;
	}, [inheritUser, integrationResource, integrations]);

	return <InheritUserLabel user={owner} disabled={disabled} inheritText={t("shared.resourceDefault")} />;
};
ResourceInheritUserLabel.isSelectLabel = true;

const IntegrationInheritUserLabel: TLabelComponent<
	TInherit,
	{ integration?: IntegrationModel | string; inheritUser?: UserModel }
> = ({ disabled = false, integration, inheritUser }) => {
	const { t } = useTranslation();
	const integrations = useIntegrations();
	const integrationModel = useMemo(() => {
		if (!integration) return;
		if (typeof integration === "string") {
			return integrations?.get(integration);
		}
		return integration.ownerId ? integration : integrations?.get(integration.id);
	}, [integrations, integration]);

	return (
		<InheritUserLabel
			user={inheritUser ?? integrationModel?.ownerId}
			disabled={disabled}
			inheritText={t("shared.integrationDefault")}
		/>
	);
};
IntegrationInheritUserLabel.isSelectLabel = true;
export { UserLabel, UserWithoutEmailLabel, ResourceInheritUserLabel, IntegrationInheritUserLabel };
