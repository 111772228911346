import constate from "constate";
import { Map } from "immutable";
import { useCallback } from "react";
import {
	createBundle,
	getBundles,
	updateBundle as apiUpdateBundle,
	deleteBundle as apiDeleteBundle
} from "api/bundles";
import { useFetchedState } from "hooks/useFetchedState";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { BundleModel } from "models/BundleModel";

const useBundles = () => {
	const {
		data: bundles,
		setData: setBundles,
		loadData: loadBundles,
		retryAction: reloadBundles,
		isLoading: isBundlesLoading
	} = useFetchedState(getBundles);

	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const getMap = useCallback(async (): Promise<Map<string, BundleModel>> => {
		if (bundles) return bundles;
		try {
			return await getBundles();
		} catch (error) {
			openGlobalErrorModal(error as Error);
			return Map();
		}
	}, [bundles, openGlobalErrorModal]);

	const saveBundle = useCallback(
		async (bundle: BundleModel) => {
			const savedBundle = await createBundle(bundle);
			const newBundles = (await getMap()).set(savedBundle.id, savedBundle);
			setBundles(newBundles);
			return savedBundle;
		},
		[getMap, setBundles]
	);

	const updateBundle = useCallback(
		async (bundle: BundleModel) => {
			const updatedBundle = await apiUpdateBundle(bundle);
			const newBundles = (await getMap()).set(updatedBundle.id, updatedBundle);
			setBundles(newBundles);
			return updatedBundle;
		},
		[getMap, setBundles]
	);

	const deleteBundle = useCallback(
		async (bundleId: string) => {
			const bundleMap = await getMap();
			const bundle = bundleMap.get(bundleId);
			try {
				if (bundle) await apiDeleteBundle(bundle);
				const newBundles = bundleMap.setIn([bundleId, "isDeleted"], true);
				setBundles(newBundles);
			} catch (error) {
				openGlobalErrorModal(error as Error);
				return;
			}
		},
		[getMap, openGlobalErrorModal, setBundles]
	);

	return {
		state: { bundles, isBundlesLoading },
		actions: { loadBundles, reloadBundles, saveBundle, updateBundle, deleteBundle }
	};
};

export const [BundlesProvider, useBundlesContext] = constate(useBundles);
