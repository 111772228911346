import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RoleBarTooltip, TRole } from "components/common/BasicRoleBar";
import { Link } from "components/common/Link";
import { NoEditIcon } from "components/ui/Icons/NoEditIcon";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { TitleBody } from "components/ui/TitleBody";
import { Tooltip } from "components/ui/Tooltip";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useStyles } from "./styles";
import { EntityNodeContent } from "../components/EntityNodeContent";
import { DeletedIndicator } from "../components/indicators/DeletedIndicator";
import { EntityLabel } from "../EntityLabel";
import type { TBaseEntityNodeProps } from "../types";

type TBaseRole = {
	isDeleted?: boolean;
	managed: boolean;
	name: string;
	id: string;
	integrationResource?: {
		integrationId: string;
		id: string;
	} | null;
};

type TRoleForLink = TBaseRole & {
	integrationResource: {
		integrationId: string;
		id: string;
	};
};

type TProps = TBaseEntityNodeProps & {
	withIcon?: boolean;
	withRoleBarTooltip?: boolean;
} & (
		| { role: TBaseRole; variant?: "standard" | "bold"; openInSameTab?: never }
		| { role: TRoleForLink; variant: "link"; openInSameTab?: boolean }
	);

const RoleTooltip: FC<{ name: string }> = ({ name }) => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "common.roleBar.roleBarRole" });

	return (
		<div className={classes.tooltipContainer}>
			<TitleBody size="small" title={t("name")} body={name} />
		</div>
	);
};

export const RoleEntity: FC<TProps> = ({
	role,
	size,
	variant: propVariant,
	className,
	openInSameTab,
	inactive: propInactive,
	withRoleBarTooltip,
	delayShowTooltip,
	...entityNodeContentProps
}) => {
	const classes = useStyles();
	const { user } = useAuthenticatedUser();
	const isAdmin = user?.isAdmin ?? false;
	const { t } = useTranslation();
	const indicators = useMemo(() => {
		const indicators: React.ReactNode[] = [];
		if (role.isDeleted) {
			indicators.push(<DeletedIndicator />);
		}
		if (!role.managed) {
			indicators.push(
				<Tooltip content={t("unmanagedRole")} className={classes.unmanagedTooltip}>
					<NoEditIcon />
				</Tooltip>
			);
		}

		return indicators;
	}, [role.isDeleted, role.managed, classes.unmanagedTooltip, t]);

	const inactive = propInactive || role.isDeleted || !role.managed;

	const variant = propVariant !== "link" || (!inactive && isAdmin) ? propVariant : undefined;

	const contentText = useMemo(() => {
		const text = role.name;
		if (variant === "link" && propVariant === "link") {
			// the second condition is for typescript to understand the type correctly and not really needed
			const linkProps = !openInSameTab ? { target: "_blank", rel: "noopener noreferrer" } : {};

			return (
				<Link
					to={`/integrations/${role.integrationResource.integrationId}/resources/${role.integrationResource.id}/roles/${role.id}`}
					className={classes.link}
					{...linkProps}>
					{text}
				</Link>
			);
		}
		return text;
	}, [role, variant, classes.link, propVariant, openInSameTab]);

	const content = useMemo(() => {
		const nodeContent = (
			<EntityNodeContent
				text={contentText}
				size={size}
				icon={<RoleIcon />}
				inactive={inactive}
				variant={variant}
				{...entityNodeContentProps}
			/>
		);
		if (withRoleBarTooltip && role.integrationResource) {
			return (
				// as TRole is needed because building custom type would be hard
				<RoleBarTooltip delayShow={delayShowTooltip} role={role as TRole}>
					{nodeContent}
				</RoleBarTooltip>
			);
		}
		return (
			<Tooltip delayShow={delayShowTooltip} content={<RoleTooltip name={role.name} />}>
				{nodeContent}
			</Tooltip>
		);
	}, [delayShowTooltip, role, size, contentText, inactive, variant, entityNodeContentProps, withRoleBarTooltip]);

	return <EntityLabel content={content} indicators={indicators} size={size} className={className} />;
};
