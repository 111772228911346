import { List, Record } from "immutable";
import { compiledClientConfig } from "config";
import { IntegrationActorModel } from "./IntegrationActorModel";
import { UserAttributeModel } from "./UserAttributeModel";
import type { TEntityOption, IEntityOption } from "utils/entityOptionType";

export type TAdminUser = UserModel & { role: "admin" };
export type TUserRole = "admin" | "regular";

const defaults = {
	email: "",
	familyName: "",
	givenName: "",
	hasAccessReviewReports: false,
	hasTasks: false,
	id: "",
	imageUrl: null as string | null,
	integrationActors: null as List<IntegrationActorModel> | null,
	isDeleted: false,
	managerIds: null as List<string> | null,
	role: "regular" as TUserRole,
	userAttributes: null as List<UserAttributeModel> | null
};

export class UserModel extends Record<typeof defaults>(defaults) implements IEntityOption<"User"> {
	get isAdmin() {
		return this.role === "admin";
	}

	get fullName() {
		const fullName = [this.givenName, this.familyName].filter(Boolean).join(" ").trim();
		return fullName.length > 0 ? fullName : this.email;
	}

	static fromServerData(data: unknown): UserModel {
		const {
			email,
			familyName,
			givenName,
			hasAccessReviewReports,
			hasTasks,
			id,
			imageUrl,
			integrationActors,
			isDeleted,
			managerIds,
			role,
			userAttributes
		} = data as typeof defaults;
		return new UserModel({
			email,
			familyName,
			givenName,
			id,
			imageUrl: compiledClientConfig.spog && imageUrl ? `/${compiledClientConfig.basePrefix}` + imageUrl : imageUrl,
			integrationActors: integrationActors ? List(integrationActors.map(IntegrationActorModel.fromServerData)) : null,
			hasAccessReviewReports,
			hasTasks,
			isDeleted,
			managerIds: managerIds ? List(managerIds) : null,
			role,
			userAttributes: userAttributes ? List(userAttributes.map(UserAttributeModel.fromServerData)) : null
		});
	}

	toEntityOption(): TEntityOption<"User"> {
		return {
			displayName: this.fullName,
			email: this.email,
			identifier: this.id,
			type: "User",
			isDeleted: this.isDeleted
		};
	}
}
