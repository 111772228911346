import classNames from "classnames";
import React, { ReactNode, useMemo } from "react";
import { Typography } from "components/ui/Typography";
import { requirePropertyOf } from "utils/security";
import { useStyles } from "./styles";
import { useTypographyVariant } from "./useTypographyVariant";

type TEntityLabelProps = {
	content: ReactNode;
	size: "large" | "medium" | "small" | "tiny";
	indicators?: ReactNode[];
	onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export const EntityLabel: FC<TEntityLabelProps> = ({ content, size, indicators, className, onClick }) => {
	const classes = useStyles();
	const textVariant = useTypographyVariant(size, "standard");
	const indicatorsElements = useMemo(
		() =>
			indicators?.map((indicator, index) =>
				typeof indicator === "string" ? (
					<Typography key={index} className={classes.textIndicator} variant={textVariant}>
						{indicator}
					</Typography>
				) : (
					<div
						key={index}
						className={classNames(
							classes.icon,
							requirePropertyOf(classes, size === "medium" || size === "large" ? "large" : "medium")
						)}>
						{indicator}
					</div>
				)
			),
		[indicators, classes, size, textVariant]
	);

	return (
		<div className={classNames(classes.labelContainer, className)} onClick={onClick}>
			{content}
			{indicatorsElements}
		</div>
	);
};
