import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	integrationImage: {
		backgroundColor: "var(--color-white)",
		"&$noBackground": {
			backgroundColor: "transparent",
			borderRadius: "0"
		},
		border: "none"
	},
	noBackground: {}
});
