import { createUseStyles } from "react-jss";

export const entitySharedStyles = {
	labelTextContent: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		"&$wrap": {
			alignItems: "flex-start"
		},
		"&$tiny": {
			gap: "var(--spacing-x1, 4px)"
		},
		gap: "var(--spacing-x2, 8px)",
		"&$inactive": {
			color: "var(--color-grey-600)",
			"& img": {
				opacity: "50%"
			}
		},
		minWidth: "0"
	},
	icon: {
		display: "flex",
		alignItems: "center",
		flexShrink: 0,
		"&$tiny, &$small": {
			height: "16px",
			width: "16px"
		},
		"&$medium": {
			height: "20px",
			width: "20px"
		},
		"&$large": {
			height: "24px",
			width: "24px"
		},
		"&$icon > *": {
			height: "100%",
			width: "100%"
		},
		"&$icon$withEmail": {
			"&$medium": {
				height: "28px",
				width: "28px"
			},
			"&$small": {
				height: "20px",
				width: "20px"
			}
		}
	},
	link: {
		// here we want the link to have this color in all of the html states
		// it's intentionally separated from the linkContainer class because of the tooltip
		color: "var(--color-blue-500)",
		"&:link, :visited, :hover, :active": {
			textDecoration: "none"
		}
	},
	tiny: {},
	small: {},
	medium: {},
	large: {},
	inactive: {},
	withEmail: {},
	withBackground: {},
	wrap: {}
};
export const useStyles = createUseStyles({
	labelContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		gap: "var(--spacing-x2, 8px)",
		minWidth: "0"
	},
	textIndicator: {
		width: "fit-content",
		height: "fit-content",
		whiteSpace: "nowrap"
	},
	...entitySharedStyles
});
