import React, { useCallback, useRef } from "react";

const MAX_MS_BETWEEN_CLICKS = 200;
export const useClicks = <TElement extends Element = HTMLDivElement>(
	onClick?: (event: React.MouseEvent<TElement>) => void,
	onDoubleClick?: (event: React.MouseEvent<TElement>) => void
) => {
	const requestIdRef = useRef<number>();
	const lastTimestampRef = useRef<DOMHighResTimeStamp>();
	const handleClick = useCallback(
		(event: React.MouseEvent<TElement>) => {
			if (!onDoubleClick) {
				onClick?.(event);
				return;
			}
			if (!onClick) return;

			if (requestIdRef.current) {
				cancelAnimationFrame(requestIdRef.current);
			}

			const runOnNextFrame = (timestamp: DOMHighResTimeStamp) => {
				if (lastTimestampRef.current === undefined) {
					lastTimestampRef.current = timestamp;
				}

				if (timestamp - lastTimestampRef.current < MAX_MS_BETWEEN_CLICKS) {
					requestIdRef.current = requestAnimationFrame(runOnNextFrame);
				} else {
					if (requestIdRef.current) {
						cancelAnimationFrame(requestIdRef.current);
					}
					lastTimestampRef.current = undefined;
					onClick?.(event);
				}
			};
			requestIdRef.current = requestAnimationFrame(runOnNextFrame);
		},
		[onClick, onDoubleClick]
	);

	const handleDoubleClick = useCallback(
		(event: React.MouseEvent<TElement>) => {
			if (requestIdRef.current) {
				cancelAnimationFrame(requestIdRef.current);
			}
			onDoubleClick?.(event);
		},
		[onDoubleClick]
	);

	return { onClick: handleClick, onDoubleClick: handleDoubleClick };
};
