import random from "lodash/random";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getUserTickets } from "api/user";
import { RequestBar } from "components/common/RequestBar";
import { REQUEST_BAR_HEIGHT } from "components/common/RequestBar/styles";
import { VirtualRequestList } from "components/common/RequestList";
import { StaticChip } from "components/ui/chips/StaticChip";
import { LoadingDots } from "components/ui/LoadingDots";
import { Section } from "components/ui/Section";
import { Skeleton } from "components/ui/Skeleton";
import { Typography } from "components/ui/Typography";
import { useTicketUpdatesContext } from "context/ticketUpdatesContext";
import { DESC, usePagination } from "hooks/usePagination";
import type { TicketModel } from "models/TicketModel";

const LIST_SIZE = 10;
const SHOWN_SIZE = 3;

const SORT_FIELDS = ["ticketNumber"];
const SEARCH_BODY = { status: ["waitingForApproval", "waitingForIT", "permissionInProgress"] };
const CANCEL_TICKET_SUBSCRIPTION_ID = "cancelTicket";

export const PendingRequestsSection: FC<TProps> = ({ className, innerRef }) => {
	const { t } = useTranslation();
	const {
		itemsForVirtualTable: requests,
		isLoading,
		totalResults,
		getPage,
		removeItem,
		setItem
	} = usePagination({
		fetchItems: getUserTickets,
		perPage: LIST_SIZE,
		sortOrder: DESC,
		sortFields: SORT_FIELDS,
		body: SEARCH_BODY
	});
	const { subscribeTicketUpdates, unsubscribeTicketUpdates } = useTicketUpdatesContext();

	useEffect(() => {
		const onTicketUpdated = (ticket: TicketModel) => {
			if (ticket.status === "cancelled") {
				try {
					removeItem(ticket.id);
				} catch (_error) {
					setItem(ticket);
				}
			} else {
				setItem(ticket);
			}
		};

		subscribeTicketUpdates(CANCEL_TICKET_SUBSCRIPTION_ID, onTicketUpdated);
		return () => unsubscribeTicketUpdates(CANCEL_TICKET_SUBSCRIPTION_ID);
	}, [removeItem, setItem, subscribeTicketUpdates, unsubscribeTicketUpdates]);

	const title = useMemo(() => {
		const pendingRequestsTextTitle = t("pages.requests.pendingRequestsSection.title");
		return (
			<>
				<Typography variant="body_sb">{pendingRequestsTextTitle}</Typography>
				<StaticChip size="small" variant="regular">
					{t("number", { value: totalResults })}
				</StaticChip>
			</>
		);
	}, [t, totalResults]);

	const renderRequest = useCallback((request?: TicketModel) => {
		if (!request) return <Skeleton key={`loading-${random(true)}`} height={REQUEST_BAR_HEIGHT} />;
		return <RequestBar key={request.id} request={request} type="userPending" openSidebarOnClick />;
	}, []);

	if (totalResults === 0) {
		return isLoading ? <LoadingDots center /> : null;
	}

	return (
		<Section className={className} innerRef={innerRef} title={title} id="requests--pending-requests-section">
			<VirtualRequestList
				fetchPage={getPage}
				perPage={LIST_SIZE}
				requestRenderer={renderRequest}
				requests={requests || []}
				totalRequests={totalResults}
				shownSize={SHOWN_SIZE}
			/>
		</Section>
	);
};
